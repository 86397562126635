import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { store } from './app/store';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';

import { planetCsvHandler } from './services/halo';
import { receiveData } from './features/livedata/livedataSlice';


const sources = [
    "https://planet.atmosphere.aero/logs/HALO/SST.txt",
    "https://planet.atmosphere.aero/logs/HALO/SLS.txt",
    "https://planet.atmosphere.aero/logs/HALO/SDA.txt",
];


function getUpdates(delta=600) {
    sources.forEach(source => {
        fetch(source + "?delta=" + delta)
            .then(planetCsvHandler)
            .then(data => {
                data.forEach(entry => {
                    if (entry.length > 2) {
                        store.dispatch(receiveData({
                            "instrument": entry[0],
                            "time": entry[1].replace(/([0-9]{4})([0-9]{2})([0-9]{2})T([0-9]{2})([0-9]{2})([0-9]{2})/,"$1-$2-$3T$4:$5:$6"),
                            "data": entry.slice(2),
                        }));
                    }
                });
            });
    });
}

getUpdates(60*60);

const job = setInterval(getUpdates, 5000);


ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
