import { createSlice } from '@reduxjs/toolkit'

const initialState = { }

const livedataSlice = createSlice({
  name: 'livedata',
  initialState,
  reducers: {
    receiveData(state, action) {
        if (state[action.payload.instrument] === undefined) {
            state[action.payload.instrument] = {};
        }
        state[action.payload.instrument][action.payload.time] = action.payload.data;
    },
  },
})

export const { receiveData } = livedataSlice.actions
export default livedataSlice.reducer
