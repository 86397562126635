import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query'
import Papa from 'papaparse'

export const planetCsvHandler = (response) => {
    return response.text()
        .then(r => r.match(/<pre>(.*?)<\/pre>/ms)[1])
        .then(t => {
            return Papa.parse(t, {delimiter: ","})["data"];
        });
}

export const haloApi = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: 'https://planet.atmosphere.aero/logs/HALO/' }),
  endpoints: (builder) => ({
    getLog: builder.query({
      query: (name) => `${name}.txt`,
      responseHandler: planetCsvHandler,
    }),
  }),
})
