import { configureStore } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import livedataReducer from '../features/livedata/livedataSlice';

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    livedata: livedataReducer,
  },
});
