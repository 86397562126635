import React from 'react';
import logo from './logo.svg';
import { Counter } from './features/counter/Counter';
import './App.css';

import Plot from 'react-plotly.js';

import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'


const adc2u = function(res, gain, Uref) {
    const TOP = 2047;
    return Uref * res / ((TOP+1) * gain);
}

const adc2rntc = function(res, gain, Uaref, Urref) {
    return 5190 / ((Urref / adc2u(res, gain, Uaref)) - 1);
}

const A = 3.9083e-3;
const B = -5.775e-7;
const C = -4.183e-12;

const RT = function(R, R0) {
    var T = -R0*A+Math.pow(((R0*A)*(R0*A))-4*R0*B*(R0-R),0.5);
    T = T / (2*R0*B);
    return T;
}

const TR = function(T, R0) {
    var R = 1 + A * T + B * T * T;
    if (T<0) R = R+(C*(T-100)*T*T*T);
    R = R * R0;
    return R;
}

const tec_temp = function(x) {
    return x/100;
}


const dataParsers = {
    SDA: [["memory: free", "bytes", v => v],
          ["K: free", "bytes", v => v],
          ["L: free", "bytes", v => v],
          ["M: free", "bytes", v => v],
          ["vnir buffers", "1", v => v],
          ["swir buffers", "1", v => v],
          ["pola buffers", "1", v => v],
          ["polb buffers", "1", v => v],
          ["vnir texp", "ms", v => v],
          ["vnir fps", "s-1", v => v],
          ["swir texp", "ms", v => v],
          ["swir fps", "s-1", v => v],
          ["pola texp", "ms", v => v],
          ["pola fps", "s-1", v => v],
          ["polb texp", "ms", v => v],
          ["polb fps", "s-1", v => v],
          ["vnir min", "DN", v => v],
          ["vnir max", "DN", v => v],
          ["vnir avg", "DN", v => v],
          ["swir min", "DN", v => v],
          ["swir max", "DN", v => v],
          ["swir avg", "DN", v => v],
          ["pola min", "DN", v => v],
          ["pola max", "DN", v => v],
          ["pola avg", "DN", v => v],
          ["polb min", "DN", v => v],
          ["polb max", "DN", v => v],
          ["polb avg", "DN", v => v],
         ],
    SST: [["TEC1: T1", "°C", v => v],
          ["TEC1: T2", "°C", v => v],
          ["TEC1: T3", "°C", v => v],
          ["TEC1: UA", "V", v => v],
          ["TEC1: UB", "V", v => v],
          ["TEC1: I", "A", v => v],
          ["TEC2: T1", "°C", v => v],
          ["TEC2: T2", "°C", v => v],
          ["TEC2: T3", "°C", v => v],
          ["TEC2: UA", "V", v => v],
          ["TEC2: UB", "V", v => v],
          ["TEC2: I", "A", v => v],
          ["TEC3: T1", "°C", v => v],
          ["TEC3: T2", "°C", v => v],
          ["TEC3: T3", "°C", v => v],
          ["TEC3: UA", "V", v => v],
          ["TEC3: UB", "V", v => v],
          ["TEC3: I", "A", v => v],
          ["TEC4: T1", "°C", v => v],
          ["TEC4: T2", "°C", v => v],
          ["TEC4: T3", "°C", v => v],
          ["TEC4: UA", "V", v => v],
          ["TEC4: UB", "V", v => v],
          ["TEC4: I", "A", v => v],
          ["memory: free", "bytes", v => v],
          ["data04: free", "bytes", v => v],
          ["data05: free", "bytes", v => v],
          ["data06: free", "bytes", v => v * 100],
         ],
    SLS: [["T2", "°C", v => RT(adc2rntc(v, 1, 1, 3.3), 1000)],
          ["P1", "bar", v => adc2u(v, 2, 1)*11*2.5/5],
          ["P2", "bar", v => adc2u(v, 2, 1)*11*250/5],
          ["PC enable", "1", v => v],
          ["tasks", "1", v => v],
          ["later tasks", "1", v => v],
          ["switch PC", "1", v => v],
          ["switch SWIR", "1", v => v],
          ["switch VNIR", "1", v => v],
          ["switch FAN", "1", v => v],
          ["switch VALVE", "1", v => v],
    ],
}

function objectMap(object, mapFn) {
  return Object.keys(object).reduce(function(result, key) {
    result[key] = mapFn(key, object[key])
    return result
  }, {})
}

const selectTimelines = createSelector(
  (state) => state.livedata,
  (livedata) => {
      return objectMap(livedata, (key, data) => {
          var times = Object.keys(data);
          times.sort();
          var series;
          if (times.length > 0) {
              const bytime = times.map(i => data[i]);
              series = bytime[bytime.length - 1].map((x,i) => bytime.map(x => x[i]));
          } else {
              series = [];
          }
          const parsers = dataParsers[key];
          if (parsers !== undefined) {
              series = parsers.reduce((obj, p, i) => {
                const s = series[i]
                if (s !== undefined) {
                    obj[p[0]] = {"units": p[1], "data": series[i].map(p[2])};
                }
                return obj;
              }, {});
          }
          return {"time": times, "series": series}
      })
  }
)

export const Foo = () => {
    const data = useSelector(selectTimelines)
    if (data["SST"] !== undefined) {
        return <div>{ data["SLS"]["series"][1] }</div>
    } else {
        return <div/>
    }
}

export const Timeline = (props) => {
    const data = useSelector(selectTimelines)
    return (
      <Plot
        data={
            props.lines.map(l => {
                if (data[l.host] === undefined || data[l.host]["series"][l.value] === undefined) {
                    return {
                        x: [],
                        x: [],
                        type: 'scatter',
                    }
                } else {
                    return {
                        x: data[l.host]["time"],
                        y: data[l.host]["series"][l.value]["data"].map(Number),
                        name: l.host + ": " + l.value + " / " + data[l.host]["series"][l.value]["units"],
                        type: 'scatter',
                      }
                }
            })
         }
        layout={ {width: 600, height: 400, title: props.title} }
      />
    );
}

function App() {
  return (
    <div className="App">
        <Timeline title="TEC box interface temperature" lines={[{host: "SST", value: "TEC1: T1"},
                                     {host: "SST", value: "TEC2: T1"},
                                     {host: "SST", value: "TEC3: T1"},
                                     {host: "SST", value: "TEC4: T1"},
                                           ]} />
        <Timeline title="TEC air interface temperature" lines={[{host: "SST", value: "TEC1: T2"},
                                     {host: "SST", value: "TEC2: T2"},
                                     {host: "SST", value: "TEC3: T2"},
                                     {host: "SST", value: "TEC4: T2"},
                                           ]} />
        <Timeline title="TEC board temperature" lines={[{host: "SST", value: "TEC1: T3"},
                                     {host: "SST", value: "TEC2: T3"},
                                     {host: "SST", value: "TEC3: T3"},
                                     {host: "SST", value: "TEC4: T3"},
                                           ]} />
        <Timeline title="TEC current" lines={[{host: "SST", value: "TEC1: I"},
                                     {host: "SST", value: "TEC2: I"},
                                     {host: "SST", value: "TEC3: I"},
                                     {host: "SST", value: "TEC4: I"},
                                           ]} />
        <Timeline title="SLS temperature" lines={[{host: "SLS", value: "T2"}]} />
        <Timeline title="box pressure" lines={[{host: "SLS", value: "P1"}]} />
        <Timeline title="bottle pressure" lines={[{host: "SLS", value: "P2"}]} />
        <Timeline title="Disk Free" lines={[{host: "SDA", value: "K: free"},
                                            {host: "SDA", value: "L: free"},
                                            {host: "SDA", value: "M: free"},
                                            {host: "SST", value: "data04: free"},
                                            {host: "SST", value: "data05: free"},
                                            {host: "SST", value: "data06: free"},
                                           ]} />
        <Timeline title="Memory Free" lines={[{host: "SDA", value: "memory: free"},
                                            {host: "SST", value: "memory: free"},
                                           ]} />
        <Timeline title="SLS Task Length" lines={[{host: "SLS", value: "tasks"},
                                            {host: "SLS", value: "later tasks"},
                                           ]} />
        <Timeline title="switches" lines={[{host: "SLS", value: "switch PC"},
                                           {host: "SLS", value: "switch VNIR"},
                                           {host: "SLS", value: "switch SWIR"},
                                           {host: "SLS", value: "switch FAN"},
                                           {host: "SLS", value: "switch VALVE"},
        ]} />
        <Timeline title="Gabber Buffers" lines={[{host: "SDA", value: "vnir buffers"},
                                                 {host: "SDA", value: "swir buffers"},
                                                 {host: "SDA", value: "pola buffers"},
                                                 {host: "SDA", value: "polb buffers"},
        ]} />
        <Timeline title="Exposure Time" lines={[{host: "SDA", value: "vnir texp"},
                                                {host: "SDA", value: "swir texp"},
                                                {host: "SDA", value: "pola texp"},
                                                {host: "SDA", value: "polb texp"},
        ]} />
        <Timeline title="Frames per Second" lines={[{host: "SDA", value: "vnir fps"},
                                                    {host: "SDA", value: "swir fps"},
                                                    {host: "SDA", value: "pola fps"},
                                                    {host: "SDA", value: "polb fps"},
        ]} />
        <Timeline title="VNIR stats" lines={[{host: "SDA", value: "vnir max"},
                                             {host: "SDA", value: "vnir avg"},
                                             {host: "SDA", value: "vnir min"},
        ]} />
        <Timeline title="SWIR stats" lines={[{host: "SDA", value: "swir max"},
                                             {host: "SDA", value: "swir avg"},
                                             {host: "SDA", value: "swir min"},
        ]} />
        <Timeline title="POLA stats" lines={[{host: "SDA", value: "pola max"},
                                             {host: "SDA", value: "pola avg"},
                                             {host: "SDA", value: "pola min"},
        ]} />
        <Timeline title="POLB stats" lines={[{host: "SDA", value: "polb max"},
                                             {host: "SDA", value: "polb avg"},
                                             {host: "SDA", value: "polb min"},
        ]} />
    </div>
  );
}

export default App;
